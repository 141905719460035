import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [0,3,5,6];

export const dictionary = {
		"/": [7],
		"/(marketing)/about": [8,[2]],
		"/admin/agency/create": [~17,[3]],
		"/admin/agency/list": [~18,[3]],
		"/admin/agency/[id]/agent/create": [~14,[3]],
		"/admin/agency/[id]/agent/list": [~15,[3]],
		"/admin/agency/[id]/agent/[agid]/transfer": [~13,[3]],
		"/admin/agency/[id]/edit": [~16,[3]],
		"/admin/campaign/list": [~20,[3]],
		"/admin/campaign/[id]": [~19,[3]],
		"/admin/client/action/prompt": [~22,[3]],
		"/admin/client/import": [~23,[3]],
		"/admin/client/list": [~24,[3]],
		"/admin/client/[id]": [~21,[3]],
		"/admin/company/list": [~25,[3]],
		"/admin/company/merge/history": [~26,[3]],
		"/admin/company/merge/review": [~27,[3]],
		"/admin/contact/classify/categories/private": [~28,[3]],
		"/admin/contact/classify/categories/topLevel": [~29,[3]],
		"/admin/contact/import": [~30,[3]],
		"/admin/contact/list": [~31,[3]],
		"/admin/dashboard": [~32,[3]],
		"/admin/investment/edit": [~33,[3]],
		"/admin/investment/list/active": [~34,[3]],
		"/admin/investment/list/complete": [~35,[3]],
		"/admin/mail/template/list": [~37,[3]],
		"/admin/mail/template/[id]": [~36,[3]],
		"/admin/member/create": [~39,[3]],
		"/admin/member/list": [~40,[3]],
		"/admin/member/[id]": [~38,[3]],
		"/admin/offering/list": [~42,[3]],
		"/admin/offering/[id]": [~41,[3]],
		"/admin/proposal/create": [~43,[3]],
		"/admin/proposal/list": [~44,[3]],
		"/admin/settings": [~45,[3]],
		"/admin/settings/categories": [~46,[3]],
		"/admin/settings/test": [~47,[3]],
		"/auth/password": [~48,[4]],
		"/auth/password/sent": [49,[4]],
		"/auth/signin": [~50,[4]],
		"/auth/signout": [51,[4]],
		"/(marketing)/contact": [9,[2]],
		"/contact/token/[token]/[...action]": [~52,[5]],
		"/(marketing)/faq": [10,[2]],
		"/(marketing)/legal": [11,[2]],
		"/member/agency/agent/create": [~54,[6]],
		"/member/agency/agent/list": [~55,[6]],
		"/member/agency/agent/[agid]": [~53,[6]],
		"/member/agency/client/list": [~57,[6]],
		"/member/agency/client/[id]": [~56,[6]],
		"/member/dashboard": [~58,[6]],
		"/member/investment/create": [~60,[6]],
		"/member/investment/list": [~61,[6]],
		"/member/investment/[id]": [~59,[6]],
		"/member/offering/list": [~63,[6]],
		"/member/offering/[id]": [~62,[6]],
		"/member/profile": [~64,[6]],
		"/member/proposal/create": [~65,[6]],
		"/member/proposal/list": [~66,[6]],
		"/member/search": [~67,[6]],
		"/member/settings": [~68,[6]],
		"/member/settings/password": [~69,[6]],
		"/member/unsubscribe": [~70,[6]],
		"/member/verify/start": [~71,[6]],
		"/(marketing)/search": [~12,[2]],
		"/sentry-example": [72]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';